import _ from 'lodash';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import AssetBrowser from 'common/components/AssetBrowser';
import { ApprovalActionButtons } from 'common/components/AssetBrowser/components';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import SocrataIcon from 'common/components/SocrataIcon';
import { summarizeCanReviewWorkflow } from 'common/core/approvals/index_new';
import { APPROVALS } from 'common/core/approvals_enums';
import FeatureFlags from 'common/feature_flags';
import { currentUserHasRight } from 'common/current_user';

const scope = 'approvals.header.breadcrumbs';

export class Approvals extends Component {
  render() {
    const defaultColumns =
      ['type', 'name', 'submitted_at', 'requester', 'intended_audience', 'status', 'actions'];
    const { PENDING, APPROVED } = APPROVALS.STATUS;
    const { IN_PROGRESS, NOT_APPLICABLE } = APPROVALS.SUBMISSION_OUTCOME_STATUS;
    const canReview = summarizeCanReviewWorkflow();
    const baseFilters = {
      approvalStatus: [PENDING, APPROVED],
      outcomeApplicationStatus: [IN_PROGRESS, NOT_APPLICABLE],
    };

    // Determine if we need to limit the target audiences to display.
    // If the user is able to review at least 1 workflow and also not able to review at least 1 workflow,
    // we should limit the displayed target audiences.
    if (canReview.length && canReview.length < canReview.totalWorkflows) {
      baseFilters.showPublicTargetAudience = canReview.public;
      baseFilters.showInternalTargetAudience = canReview.internal;
    }

    const tabs = {
      [constants.TAB_MY_QUEUE]: {
        props: {
          actionElement: ApprovalActionButtons,
          columns: defaultColumns,
          baseFilters
        }
      }
    };

    const approvalsSettingsLink = currentUserHasRight('configure_approvals') && (
      <div className="approvals-header-content">
        <div className="settings_link">
          <a href={'/admin/approvals/settings'}>
            <SocrataIcon name="settings-switch" />
            {I18n.t('approvals_settings', { scope })}
          </a>
        </div>
      </div>);

    return (
      <AssetBrowser
        approvalsSettingsLink={approvalsSettingsLink}
        enableAssetInventoryActions={false}
        initialTab={constants.TAB_MY_QUEUE}
        showAssetCounts={false}
        showAwaitingApprovalFilter={false}
        showFilters
        showSearchField
        tabs={tabs} />
    );
  }
}

export default Approvals;
